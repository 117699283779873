import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Archive } from '../components/posts'

export default function NewsPage({ data }) {
  const posts = data.allStrapiPost.edges.map(({ node }) => node)
  const categories = data.allStrapiPostCategory.edges.map(({ node }) => {
    return {
      id: node.id,
      title: node.title,
      slug: node.slug,
      count: node.posts.length || 0,
    }
  })

  posts.sort(
    (d1, d2) =>
      new Date(d2.datetime).getTime() - new Date(d1.datetime).getTime()
  )

  return (
    <Layout>
      <SEO title="News" />
      <Archive posts={posts} categories={categories} />
    </Layout>
  )
}

export const query = graphql`
  query GetPosts {
    # Posts
    allStrapiPost {
      edges {
        node {
          id
          title
          slug
          content
          datetime
          category {
            id
            title
          }
        }
      }
    }

    # All categories and their posts to generate category filter (and count).
    allStrapiPostCategory {
      edges {
        node {
          posts {
            id
          }
          id
          title
          slug
        }
      }
    }
  }
`
